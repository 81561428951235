<template>
  <div class="container flex">
    <div class="con_left full_height">
      <div id="main" ref="mapRef"></div>
      <div class="select flex-a flex-je">
        <span>{{ t('174') }}:&nbsp;&nbsp;&nbsp;</span>
        <el-cascader
          ref="cityRef"
          class="common-input"
          style="width: 30%"
          :show-all-levels="false"
          v-model="region"
          :placeholder="t('175')"
          :options="city"
          :props="cityCasDer"
          @change="cityChange"
          popper-class="teamedit disabled_hide_cacader"
        />
        <div class="selectop" @click="goBack()">
          <div class="ltop"></div>
          <div class="rtop"></div>
          <div class="lbm"></div>
          <div class="rbm"></div>
          {{ t('176') }}
        </div>
      </div>
      <div class="month flex-dc">
        <div
          class="box"
          v-for="(item, index) in timeList"
          :key="index"
          :class="monthType === item.value ? 'active' : ''"
          @click="changeType(item)"
        >
          <div class="ltop"></div>
          <div class="rtop"></div>
          <div class="lbm"></div>
          <div class="rbm"></div>
          {{ item.desc }}
        </div>
      </div>
    </div>
    <div class="con_right full_height">
      <div class="right_item right_top">
        <!-- 边框 -->
        <div class="item_tp">
          <img src="./../asset/img/home/bm.png" :alt="t('177')" />
        </div>
        <div class="item_bm">
          <img src="./../asset/img/home/bm.png" :alt="t('177')" />
        </div>
        <div class="right_item_top flex-a">
          <div class="rla">
            <div class="rlr">
              <img src="./../asset/img/home/l_bg.png" :alt="t('177')" />
              <span>{{ t('178') }}</span>
            </div>
          </div>
          <div class="rra">
            <!-- <div class="rrr">
              <img
                :src="
                  FlyShowImg === 1
                    ? require('./../asset/img/home/jx_bg.png')
                    : require('./../asset/img/home/jx.png')
                "
                alt=""
              />
              <img
                :src="
                  FlyShowImg === 2
                    ? require('./../asset/img/home/jx_bg.png')
                    : require('./../asset/img/home/jx.png')
                "
                alt=""
              />
              <img
                :src="
                  FlyShowImg === 3
                    ? require('./../asset/img/home/dx_bg.png')
                    : require('./../asset/img/home/dx.png')
                "
                alt=""
              /> -->
            <!-- <span>{{ t('164') }}</span> -->
            <!-- @click="rightChange('FLY', 'AIRPORTDATA')" -->
            <!-- <span>{{ t('179') }}</span> -->
            <!-- @click="rightChange('FLY', 'UAVDATA')" -->
            <!-- <span>{{ t('180') }}</span> -->
            <!-- @click="rightChange('FLY', 'PERSONNELDATA')" -->
            <!-- </div> -->
          </div>
        </div>
        <div class="box flex-sb">
          <div
            class="fly_one flex-dc flex-fac"
            v-for="(item, index) in flyData"
            :key="index"
          >
            <div class="fly_number">
              {{
                index == 0
                  ? item.flyNumber == ""
                    ? 0 + t("181")
                    : item.flyNumber + t("181")
                  : index == 1
                  ? item.flyTime == ""
                    ? 0 + t("182")
                    : item.flyTime + t("182")
                  : index === 2
                  ? item.flyDistance == ""
                    ? 0 + t("42")
                    : item.flyDistance + t("42")
                  : ""
              }}
            </div>
            <div class="imgbox"><img :src="item.url" alt="" /></div>
            <div class="fly_text">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div class="right_item right_center">
        <!-- 边框 -->
        <div class="item_tp">
          <img src="./../asset/img/home/bm.png" :alt="t('177')" />
        </div>
        <div class="item_bm">
          <img src="./../asset/img/home/bm.png" :alt="t('177')" />
        </div>
        <div class="right_item_top flex-a">
          <div class="rla">
            <div class="rlr">
              <img src="./../asset/img/home/l_bg.png" :alt="t('177')" />
              <span>{{ t('183') }}</span>
            </div>
          </div>
          <div class="rra">
            <!-- <div class="rrr">
              <img
                :src="
                  NumShowImg === 4
                    ? require('./../asset/img/home/jx_bg.png')
                    : require('./../asset/img/home/jx.png')
                "
                alt=""
              />
              <img
                :src="
                  NumShowImg === 5
                    ? require('./../asset/img/home/jx_bg.png')
                    : require('./../asset/img/home/jx.png')
                "
                alt=""
              />
              <img
                :src="
                  NumShowImg === 6
                    ? require('./../asset/img/home/dx_bg.png')
                    : require('./../asset/img/home/dx.png')
                "
                alt=""
              />
              <span>{{ t('164') }}</span> -->
            <!-- @click="rightChange('Num', 'AIRPORTNUM')" -->
            <!-- <span>{{ t('179') }}</span> -->
            <!-- @click="rightChange('Num', 'UAVNUM')" -->
            <!-- <span>{{ t('180') }}</span> -->
            <!-- @click="rightChange('Num', 'PERSONNELNUM')" -->
            <!-- </div> -->
          </div>
        </div>
        <!-- 3d饼图 -->
        <div class="pie_box">
          <div id="pie_main" ref="pieRef"></div>
          <!-- <div class="pie_bg"></div> -->
        </div>
      </div>

      <div class="right_item right_bottom">
        <!-- 边框 -->
        <div class="item_tp">
          <img src="./../asset/img/home/bm.png" :alt="t('177')" />
        </div>
        <div class="item_bm">
          <img src="./../asset/img/home/bm.png" :alt="t('177')" />
        </div>
        <div class="right_item_top flex-a">
          <div class="rla">
            <div class="rlr">
              <img src="./../asset/img/home/l_bg.png" :alt="t('177')" />
              <span>{{ t('184') }}</span>
            </div>
          </div>
          <div class="rra">
            <!-- <div class="rrr">
              <img
                :src="
                  TjShowImg === 7
                    ? require('./../asset/img/home/jx_bg.png')
                    : require('./../asset/img/home/jx.png')
                "
                alt=""
              />
              <img
                :src="
                  TjShowImg === 8
                    ? require('./../asset/img/home/jx_bg.png')
                    : require('./../asset/img/home/jx.png')
                "
                alt=""
              />
              <img
                :src="
                  TjShowImg === 9
                    ? require('./../asset/img/home/dx_bg.png')
                    : require('./../asset/img/home/dx.png')
                "
                alt=""
              />
              <span>{{ t('164') }}</span> -->
            <!-- @click="rightChange('Time', 'AIRPORTCOUNT')" -->
            <!-- <span>{{ t('179') }}</span> -->
            <!-- @click="rightChange('Time', 'UAVCOUNT')" -->
            <!-- <span>{{ t('180') }}</span> -->
            <!-- @click="rightChange('Time', 'PERSONNELCOUNT')" -->
            <!-- </div> -->
          </div>
        </div>
        <!-- 柱状图 -->
        <div class="bar_box">
          <div id="bar_main" ref="barRef"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { t } from '../languages';
// import $ from "jquery";
import * as echarts from "echarts";
import "echarts-gl";
import { getPie3D, getParametricEquation } from "./chart.js"; //工具类js
import { onMounted, onUnmounted, ref, watch } from "vue";
import { allAreaCode } from "../../public/allMap.js";
import axios from "axios";
import flyQuality from "../network/home.js";
import { ElMessage } from "element-plus";
import { findP, flatten, getAdcode, isEqual } from "@/utils/openRegion";
import { store } from "@/store";
import emitter from "../utils/mitt";
const chinaJson = require("../../public/china.json");
// let publicUrl = "https://geo.datav.aliyun.com/areas_v2/bound/";
const timeList = ref([]);
const region = ref(""); //选择区域
const cityRef = ref(null);
let pieWidth = null;

// 开放区域
const openCity = ref([]);
fetch("json/openMapTree.json")
  .then((d) => d.json())
  .then((res) => {
    openCity.value = res[0].children;
  });

const timer = ref(0);

//获取上次选择区域
const regoinCode = ref(null);
const getRegoinCode = () => {
  const regoinCode = store.state.system.defaultRegion;
  let isRegoinCode =  regoinCode instanceof Object ? regoinCode[0]:regoinCode;
  if (isRegoinCode === null) return;
  if (isRegoinCode === 1) {
      region.value = isRegoinCode;
      initEcharts(chinaJson, t("7"), mapChart, allAreaCode); //地图
      getFlyTotal(monthType.value, "");
      getModelStat(monthType.value, "");
      getFlyStatNum(monthType.value, "");
  } else {
    let name = allAreaCode.filter(
      (areaJson) => areaJson.adcode === isRegoinCode
    )[0]?.name;
    if (isRegoinCode === region.value) return;
    region.value = isRegoinCode;
    getGeoJson(isRegoinCode)
      .then((regionGeoJson) => {
        if (name === t("7")) {
          getFlyTotal(monthType.value, "");
          getModelStat(monthType.value, "");
          getFlyStatNum(monthType.value, "");
          initEcharts(regionGeoJson.data, name, mapChart, allAreaCode);
        } else {
          getFlyTotal(monthType.value, name);
          getModelStat(monthType.value, name);
          getFlyStatNum(monthType.value, name);
          initEcharts(regionGeoJson.data, name, mapChart, allAreaCode);
        }
      })
      .catch((err) => {
        // region.value = 1;
        // initEcharts(chinaJson, t("7"), mapChart, allAreaCode);
      });
    // initEcharts(chinaJson, t("7"), mapChart, allAreaCode); //地图
  }
  // }
};

// 柱状图数据过多滚动定时器
const barTimer = ref(0);

// 飞行数据
const flyData = ref([
  {
    flyNumber: "",
    type: "",
    url: require("../asset/img/home/icon.png"),
    title: t("183"),
  },
  {
    flyTime: "",
    type: "",
    url: require("../asset/img/home/icon1.png"),
    title: t("185"),
  },
  {
    flyDistance: "",
    type: "",
    url: require("../asset/img/home/icon2.png"),
    title: t("186"),
  },
]);

// 饼图数据
const optionData = ref([
  {
    name: t("187"),
    value: 0,
  },
]);

// 统计数据
const tjData = ref([]);
const barXName = ref([]);

// 获取本周 上周
const getDateEnumList = async () => {
  let res = await flyQuality.getDateEnumList();
  if (res.resultStatus) {
    timeList.value = res.resultData;
  }
};

getDateEnumList();

const monthType = ref("THIS_WEEK");
const timeName = ref(t("188"));
// 周期事件
const changeType = (item) => {
  monthType.value = item.value;
  timeName.value = item.desc;
  if ((region.value !== "") & (region.value !== 1)) {
    let name = allAreaCode.filter(
      (areaJson) => areaJson.adcode == region.value
    )[0]?.name;
    getFlyTotal(item.value, name);
    getModelStat(item.value, name);
    getFlyStatNum(item.value, name);
  } else if (region.value === 1) {
    getFlyTotal(item.value, "");
    getModelStat(item.value, "");
    getFlyStatNum(item.value, "");
  } else {
    getFlyTotal(item.value, region.value);
    getModelStat(item.value, region.value);
    getFlyStatNum(item.value, region.value);
  }
};

// 获取飞行数据
const getFlyTotal = async (type, region) => {
  let res = await flyQuality.getFlyTotal(type, region);
  if (res.resultStatus) {
    const { num, totalDistance, totalTime } = res.resultData;
    flyData.value[0].flyNumber = num;
    flyData.value[1].flyTime = totalTime;
    flyData.value[2].flyDistance = totalDistance;
  }
};
// getFlyTotal(monthType.value, region.value);
// 获取飞行次数
const getModelStat = async (type, region) => {
  let res = await flyQuality.getModelStat(type, region);
  if (res.resultStatus && res.resultData == "") {
    let pieData = [
      {
        name: t("187"),
        value: 0,
      },
    ];
    optionData.value = pieData;
    initPieChart(); //3d饼图
  } else {
    optionData.value = res.resultData;
    initPieChart(); //3d饼图
  }
};
// getModelStat(monthType.value, region.value);
// 获取飞行次数统计
const getFlyStatNum = async (type, region) => {
  let res = await flyQuality.getFlyStatNum(type, region);
  if (res.resultStatus) {
    tjData.value = res.resultData;
    let len = res.resultData.length;
    let zhou = [t("189"), t("190"), t("191"), t("192"), t("193"), t("194"), t("195")];
    let forMon = [t("196"), t("197"), t("198"), t("199")];
    let fivrMon = [t("196"), t("197"), t("198"), t("199"), t("200")];
    let sixMon = [t("196"), t("197"), t("198"), t("199"), t("200"), t("201")];
    barXName.value =
      len === 7
        ? zhou
        : len === 6
        ? sixMon
        : len === 5
        ? fivrMon
        : len === 4
        ? forMon
        : forMon;
    initBarChart(); //柱状图
  }
};
// getFlyStatNum(monthType.value, region.value);
// 获取区域任务统计
// const getRegoinStat = async (region) => {
//   let res = await flyQuality.getRegoinStat(region);
//   if (res.resultStatus) {
//     mapTool.value = res.resultData;
//     //   timeList.value = res.resultData;
//   }
// };

// function itemStyle() {
//   const color = ["#e6e42a", "#e7744e", ];
//     optionData.value.forEach((item, index) => {
//         item.itemStyle = {
//             color: color[index],
//         };
//     });
// };
// itemStyle();

// 下拉配置
const cityCasDer = {
  value: "adcode",
  label: "title",
  checkStrictly: true,
  children: "children",
  expandTrigger: "click",
};

const oldRegion = ref("");
// const backVal = ref(0);
const city = ref([]);
fetch("json/map-tree.json")
  .then((d) => d.json())
  .then((res) => {
    city.value = res[0].children;
    disabledCity();
  });
const disabledCity = () => {
  const currentMap = store.state.system.openRegion;
  if (currentMap.length === 0) return;
  const mapCity = city.value;
  const isCountry = currentMap.some((item) => {
    return item === 1;
  });
  if (isCountry) {
    return;
  } else {
    const cityList = mapCity.map((item) => {
      return item.adcode;
    });
    // 这里漏了没有某个省没有全选的情况
    const c = currentMap.filter(
      (v) => cityList.findIndex((el) => el === v) != -1
    );
    const faterAdcode = currentMap.map((item) => {
      return findP(item, openCity.value);
    });
    let getFaterAdcode = flatten(faterAdcode);
    getFaterAdcode.map((item, index) => {
      if (item === 1) {
        getFaterAdcode.splice(index, 1);
      }
    });
    const getNeedAdcode = getFaterAdcode.filter(
      (v) => currentMap.findIndex((el) => el === v) === -1
    ); //去重过滤掉拿到没有全选的省adcode
    // console.log(getNeedAdcode);
    const needAdcode = [...c, ...getNeedAdcode]; //合并拿到打勾的省adcode
    const acceptAdcode = cityList.filter(
      (v) => needAdcode.findIndex((el) => el === v) === -1
    ); //过滤掉需要的adcode
    // 添加禁用属性
    mapCity.map((item) => {
      acceptAdcode.forEach((items) => {
        if (items === item.adcode) {
          item.disabled = true;
        }
      });
    });
    // console.log(mapCity);
    city.value = mapCity;
  }
};
emitter.on("getOpenRegion", () => {
  disabledCity();
});

const rightType = {
  AIRPORTDATA: 1,
  UAVDATA: 2,
  PERSONNELDATA: 3,
  AIRPORTNUM: 4,
  UAVNUM: 5,
  PERSONNELNUM: 6,
  AIRPORTCOUNT: 7,
  UAVCOUNT: 8,
  PERSONNELCOUNT: 9,
};
const FlyShowImg = ref(1); //飞行数据
const NumShowImg = ref(4); //飞行次数
const TjShowImg = ref(7); //飞行统计
const rightChange = (type, types) => {
  if (type === "FLY") {
    let renyuan = [200, 100, 500];
    let jc = [500, 300, 1500];
    let fx = [2330, 1000, 3000];
    FlyShowImg.value = rightType[types];
    if (rightType[types] === 1) {
      flyData.value.map((item, index) => {
        if (index == 0) {
          item.flyNumber = renyuan[0];
        }
        if (index == 1) {
          item.flyTime = renyuan[1];
        }
        if (index == 2) {
          item.flyDistance = renyuan[2];
        }
      });
    }
    if (rightType[types] === 2) {
      flyData.value.map((item, index) => {
        if (index == 0) {
          item.flyNumber = jc[0];
        }
        if (index == 1) {
          item.flyTime = jc[1];
        }
        if (index == 2) {
          item.flyDistance = jc[2];
        }
      });
    }
    if (rightType[types] === 3) {
      flyData.value.map((item, index) => {
        if (index == 0) {
          item.flyNumber = fx[0];
        }
        if (index == 1) {
          item.flyTime = fx[1];
        }
        if (index == 2) {
          item.flyDistance = fx[2];
        }
      });
    }
  }
  if (type === "Num") {
    let renyuan = [10000, 12116, 16616, 7888];
    let jc = [10000, 8000, 1500, 3333];
    let fx = [5555, 7893, 3256, 4896];
    NumShowImg.value = rightType[types];
    if (rightType[types] === 4) {
      optionData.value.map((item, index) => {
        if (index == 0) {
          item.value = renyuan[0];
        }
        if (index == 1) {
          item.value = renyuan[1];
        }
        if (index == 2) {
          item.value = renyuan[2];
        }
        if (index == 3) {
          item.value = renyuan[3];
        }
      });
      initPieChart();
    }
    if (rightType[types] === 5) {
      optionData.value.map((item, index) => {
        if (index == 0) {
          item.value = jc[0];
        }
        if (index == 1) {
          item.value = jc[1];
        }
        if (index == 2) {
          item.value = jc[2];
        }
        if (index == 3) {
          item.value = jc[3];
        }
      });
      initPieChart();
    }
    if (rightType[types] === 6) {
      optionData.value.map((item, index) => {
        if (index == 0) {
          item.value = fx[0];
        }
        if (index == 1) {
          item.value = fx[1];
        }
        if (index == 2) {
          item.value = fx[2];
        }
        if (index == 3) {
          item.value = fx[3];
        }
      });
      initPieChart();
    }
  }
  if (type === "Time") {
    TjShowImg.value = rightType[types];
  }
};

// 地图数据
const mapTool = ref({
  list: [],
  nestNum: 0,
  memberNum: 0,
});

const mapRef = ref(null);
const pieRef = ref(null);
const barRef = ref(null);

let mapChart = null;
let pieChart = null;
let barChart = null;

onMounted(() => {
  mapChart = echarts.init(document.getElementById("main")); //地图
  pieChart = echarts.init(document.getElementById("pie_main")); //饼图
  barChart = echarts.init(document.getElementById("bar_main")); //柱状图

  initPieChart(); //3d饼图
  initBarChart(); //柱状图
  getRegoinCode();
});
emitter.on("getDefaultRegion", () => {
  getRegoinCode();
});
// 地图
//echarts绘图
function initEcharts(geoJson, name, mapChart, alladcode) {
  // mapChart.showLoading({
  //   text: "loading",
  //   color: "#c23531",
  //   textColor: "#000",
  //   maskColor: "rgba(255, 255, 255, 0.2)",
  //   zlevel: 0,
  // });
  echarts.registerMap(name, geoJson);
  let option = {
    title: {
      text: name,
      left: "center",
      show: false,
    },
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0,0,0,0)",
      triggerOn: "click", //mousemove click
      enterable: true, //交互
      borderWidth: 0,
      borderColor: "rgba(0,0,0,0)",
      padding: 0,
      // hideDelay: 500000,
      extraCssText: "box-shadow: none;", //去除边框毛玻璃
      formatter: function (params, ticket, callback) {
        //  clearTimeout(timer.value);
        let clickRegionCode = alladcode.filter(
          (areaJson) => areaJson.name === params.name
        )[0].adcode;
        const arr = store.state.system.openRegion;
        const arr1 = arr.map((item) => {
          return findP(item, openCity.value);
        });
        const arr2 = flatten(arr1); //扁平化数组去重

        const c = arr2.filter((v) => arr.findIndex((el) => el === v) === -1); //去重过滤拿到父id
        const arr3 = [...arr, ...c];
        const arrList = arr3.map((item) => {
          if (item === clickRegionCode) {
            return true;
          } else {
            return false;
          }
        });
        const isOpen = arrList.some((item) => {
          return item === true;
        });
        if (isOpen) {
          // timer.value = setTimeout(() => {
          let str = null;
          flyQuality.getRegoinStat(params.name).then((res) => {
            if (res.resultStatus) {
              mapTool.value = res.resultData;
              let data = mapTool.value;
              str = `<div style="width:20.9375rem;height:14.25rem;background:rgba(15,79,107,0.8);border:.125rem solid rgba(0,166,208);border-radius: .25rem;">
                    <div style="width:100%;height:2.5rem;line-height:2.5rem;border-bottom:1px solid rgba(2,193,178,0.7); display:flex;aligin-item:center;justify-content: space-between;">
                        <span style=" margin-left:.625rem;color:#fff;font-size:12px; display:inline-block;"> ${
                          params.name
                        }</span>
                        <div style="display:flex;aligin-item:center;margin-right:.625rem;">
                            <span style="margin-left:.625rem;color:#fff;font-size:12px;">${ t('164') }：${
                              data.nestNum
                            }${ t('202') }</span>
                            <span style="margin-left:.625rem;color:#fff;font-size:12px;">${ t('180') }：${
                              data.memberNum
                            }${ t('203') }</span>
                        </div>
                    </div>
                    <div style="height:calc(100% - 2.5rem);overflow: hidden;overflow-y: auto;">
                          ${
                            data.list === ""
                              ? ""
                              : data.list
                                  .map((item) => {
                                    return `<div style="padding:5px 0 5px 0;display:flex;aligin-item:center;justify-content: space-between;">
                          
                         <div>
                         <i style="display:inline-block;width:8px;height:8px; border-radius: 8px;background:#16d6ff;margin:0 8px"></i>
                          <span style="font-size: 12px;color: #CCEFF0;">${
                            item.taskName.length > 15
                              ? item.taskName.slice(0, 15) + "..."
                              : item.taskName
                          }</span>
                         </div>
                         <span style="font-size: 12px;margin-right:1.25rem;;color: #CCEFF0;display:inline-block;">${
                           item.createTime
                         }</span>
                         </div>`;
                                  })
                                  .join("")
                          }    
                    </div>
                  </div>`;

              callback(ticket, str);
            }
          });
          // }, 250);
        } else {
          ElMessage.warning(t("204"));
          // errText = t("205");
          // return errText
        }
        return "loading";
      },
    },
    series: [
      {
        type: "map",
        name: "map",
        map: name,
        itemStyle: {
          areaColor: "#23A5F7",
          borderColor: "#C8F5FF",
          borderWidth: 1,
          borderType: "solid",
          shadowColor: "rgba(3,58,134,0.36)",
          shadowBlur: 10,
          shadowColor: "rgba(3,58,134,0.36)",
          shadowOffsetX: 10,
          shadowOffsetY: 10,
        },
        label: {
          color: "#ffffff",
          fontSize: 12,
          show: true,
          align: "center",
          verticalAlign: "middle",
          // position: ["50%", "50%"],
        },
        emphasis: {
          itemStyle: {
            areaColor: "RGB(251,138,90,1)",
            borderColor: "#C8F5FF",
            borderWidth: 1,
            borderType: "solid",
            shadowColor: "RGB(3,58,134,0.36)",
            shadowBlur: 10,
            shadowColor: "RGB(3,58,134,0.36)",
            shadowOffsetX: 10,
            shadowOffsetY: 10,
          },
          label: {
            color: "#fff",
            fontSize: 18,
            show: true,
            align: "center",
            // position: ["50%", "50%"],
          },
        },
      },
    ],
  };

  if (name === t("206")) {
    option.series[0].center = [109.844902, 19.0392];
    option.series[0].layoutCenter = ["50%", "50%"];
    option.series[0].layoutSize = "400%";
  } else {
    //非显示海南时，将设置的参数恢复默认值
    option.series[0].center = undefined;
    option.series[0].layoutCenter = undefined;
    option.series[0].layoutSize = undefined;
  }

  mapChart.setOption(option);
  // 解绑click事件
  mapChart.off("dblclick");
  mapChart.off("click");
  //给地图添加监听事件
  // 双击
  mapChart.on("dblclick", (params) => {
    clearTimeout(timer.value);
    mapChart.dispatchAction({
      type: "hideTip",
    });
    let clickRegionCode = alladcode.filter(
      (areaJson) => areaJson.name === params.name
    )[0].adcode;
    const arr = store.state.system.openRegion;
    const arr1 = arr.map((item) => {
      return findP(item, openCity.value);
    });
    const arr2 = flatten(arr1); //扁平化数组去重

    const c = arr2.filter((v) => arr.findIndex((el) => el === v) === -1); //去重过滤拿到父id
    const arr3 = [...arr, ...c];
    const arrList = arr3.map((item) => {
      if (item === clickRegionCode) {
        return true;
      } else {
        return false;
      }
    });
    const isOpen = arrList.some((item) => {
      return item === true;
    });
    if (isOpen) {
      region.value = clickRegionCode;
      getGeoJson(clickRegionCode)
        .then((regionGeoJson) => {
          getFlyTotal(monthType.value, params.name);
          getModelStat(monthType.value, params.name);
          getFlyStatNum(monthType.value, params.name);
          initEcharts(regionGeoJson.data, params.name, mapChart, alladcode);
        })
        .catch((err) => {
          const errAdcode = findP(clickRegionCode, openCity.value);
          errAdcode.map((item, index) => {
            if (item === 1 || item === clickRegionCode) {
              errAdcode.splice(index, 1);
            }
          });
          region.value = errAdcode[0];
          // initEcharts(chinaJson, t("7"), mapChart, allAreaCode);
        });
    } else {
      ElMessage.warning(t("204"));
    }
  });
  // 单击
  mapChart.on("click", (params) => {
    clearTimeout(timer.value);
    let clickRegionCode = alladcode.filter(
      (areaJson) => areaJson.name === params.name
    )[0].adcode;
    const arr = store.state.system.openRegion;
    const arr1 = arr.map((item) => {
      return findP(item, openCity.value);
    });
    const arr2 = flatten(arr1); //扁平化数组去重

    const c = arr2.filter((v) => arr.findIndex((el) => el === v) === -1); //去重过滤拿到父id
    const arr3 = [...arr, ...c];
    const arrList = arr3.map((item) => {
      if (item === clickRegionCode) {
        return true;
      } else {
        return false;
      }
    });
    const isOpen = arrList.some((item) => {
      return item === true;
    });
    if (isOpen) {
      getFlyTotal(monthType.value, params.name);
      getModelStat(monthType.value, params.name);
      getFlyStatNum(monthType.value, params.name);
      // region.value = clickRegionCode;
      // getGeoJson(clickRegionCode)
      //   .then((regionGeoJson) => {

      //     // initEcharts(regionGeoJson.data, params.name, mapChart, alladcode);
      //   })
      //   .catch((err) => {
      //     return;
      //     // initEcharts(chinaJson, t("7"), mapChart, allAreaCode);
      //   });
    }
  });
}

//获取地图json数据
async function getGeoJson(jsonName) {
  let isSure = require(`../../public/sheng/${jsonName}.json`);
  if (isSure != "") {
    return await axios.get(`./sheng/${jsonName}.json`);
  } else {
    return;
  }
}

// 省市选择器下钻
const cityChange = () => {
  let adcode = 0;
  if (region.value.length == 1) {
    adcode = `${region.value[0]}`;
  } else if (region.value.length == 2) {
    adcode = `${region.value[1]}`;
  } else if (region.value.length == 3) {
    adcode = `${region.value[2]}`;
  }
  let name = allAreaCode.filter((areaJson) => areaJson.adcode == adcode)[0]
    ?.name;
  getGeoJson(adcode)
    .then((regionGeoJson) => {
      if (name === t("7")) {
        getFlyTotal(monthType.value, "");
        getModelStat(monthType.value, "");
        getFlyStatNum(monthType.value, "");
      } else {
        getFlyTotal(monthType.value, name);
        getModelStat(monthType.value, name);
        getFlyStatNum(monthType.value, name);
      }

      initEcharts(regionGeoJson.data, name, mapChart, allAreaCode);
      // saveRegoinCode(adcode);
    })
    .catch((err) => {
      region.value = 1;
      initEcharts(chinaJson, t("7"), mapChart, allAreaCode);
    });
  cityRef.value.togglePopperVisible();
};

// 返回上一级
const goBack = () => {
  const arr = store.state.system.openRegion;
  const isOne = arr.some((item) => {
    return item === 1;
  });
  if (isOne) {
    region.value = 1;
    initEcharts(chinaJson, t("7"), mapChart, allAreaCode);
    getFlyTotal(monthType.value, "");
    getModelStat(monthType.value, "");
    getFlyStatNum(monthType.value, "");
  } else {
    return;
  }
};

// 3d饼图数据

// 3d饼图
function initPieChart() {
  let data = optionData.value;
  pieChart.clear();
  let option = getPie3D(data, 0.6, 210, 26, 10, 1,pieWidth);
  option.series.push({
    name: "name", //自己根据场景修改
    backgroundColor: "transparent",
    type: "pie",
    label: {
      show: false,
      opacity: 1,
      fontSize: 13,
      lineHeight: 20,
    },
    startAngle: 360, // 起始角度，支持范围[0, 360]。
    clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
    radius: ["0%", "50%"],
    center: ["25%", "50%"],
    data: data,
    itemStyle: {
      opacity: 0, //这里必须是0，不然2d的图会覆盖在表面
    },
    pieStatus: {
      selected: false,
      hovered: false,
      k: 0,
    },
    pieData: {
      endRatio: 0,
      name: "",
      startRatio: 0,
      value: 0,
    },
  });

  pieChart.setOption(option);

  function bindListen(pieChart) {
    // let optionName = "option"
    let selectedIndex = "";
    // let hoveredIndex = "";
    let isSelected;
    let isHovered;
    let startRatio;
    let endRatio;
    let k;
    // 监听点击事件，实现选中效果（单选）
    pieChart.on("click", (params) => {
      // console.log(
      //   this[optionName].series[params.seriesIndex],
      //   "this[optionName].series[params.seriesIndex].pieStatus"
      // );
      // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
      let isSelected = !option.series[params.seriesIndex].pieStatus?.selected;
      let isHovered = option.series[params.seriesIndex].pieStatus?.hovered;
      let k = option.series[params.seriesIndex].pieStatus?.k;
      let startRatio = option.series[params.seriesIndex].pieData?.startRatio;
      let endRatio = option.series[params.seriesIndex].pieData?.endRatio;
      // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
      if (selectedIndex !== "" && selectedIndex !== params.seriesIndex) {
        option.series[selectedIndex].parametricEquation = getParametricEquation(
          option.series[selectedIndex].pieData.startRatio,
          option.series[selectedIndex].pieData.endRatio,
          false,
          false,
          k,
          option.series[selectedIndex].pieData.value
        );
        option.series[selectedIndex].pieStatus.selected = false;
      }
      // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
      option.series[params.seriesIndex].parametricEquation =
        getParametricEquation(
          startRatio,
          endRatio,
          isSelected,
          isHovered,
          k,
          option.series[params.seriesIndex].pieData?.value
        );
      option.series[params.seriesIndex].pieStatus.selected = isSelected;
      // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
      selectedIndex = isSelected ? params.seriesIndex : null;
      // 使用更新后的 option，渲染图表
      pieChart.setOption(option);
    });
    // 监听 mouseover，近似实现高亮（放大）效果
    // pieChart.on("mouseover", (params) => {
    //   // 准备重新渲染扇形所需的参数
    //   // 如果触发 mouseover 的扇形当前已高亮，则不做操作
    //   if (hoveredIndex === params.seriesIndex) {
    //     return;

    //     // 否则进行高亮及必要的取消高亮操作
    //   } else {
    //     // console.log(option.series);
    //     // if (option.series.length === 3) return;
    //     if (
    //       option.series[params.seriesIndex].name !== "name" &&
    //       option.series[params.seriesIndex].name !== "mouseoutSeries" &&
    //       option.series[params.seriesIndex].name !== t("187")
    //       // 过滤name和mouseoutSeries防止报错
    //     ) {
    //       // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
    //       if (hoveredIndex !== "") {
    //         // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
    //         isSelected = option.series[hoveredIndex].pieStatus?.selected;
    //         isHovered = false;
    //         startRatio = option.series[hoveredIndex].pieData?.startRatio;
    //         endRatio = option.series[hoveredIndex].pieData?.endRatio;
    //         k = option.series[hoveredIndex].pieStatus?.k;

    //         // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
    //         option.series[hoveredIndex].parametricEquation =
    //           getParametricEquation(
    //             startRatio,
    //             endRatio,
    //             isSelected,
    //             isHovered,
    //             k,
    //             option.series[hoveredIndex].pieData?.value
    //           );
    //         option.series[hoveredIndex].pieStatus.hovered = isHovered;

    //         // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
    //         hoveredIndex = "";
    //       }

    //       // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
    //       if (params.seriesName !== "mouseoutSeries") {
    //         // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
    //         isSelected = option.series[params.seriesIndex].pieStatus.selected;
    //         isHovered = true;
    //         startRatio = option.series[params.seriesIndex].pieData.startRatio;
    //         endRatio = option.series[params.seriesIndex].pieData.endRatio;
    //         k = option.series[params.seriesIndex].pieStatus.k;

    //         // 对当前点击的扇形，执行高亮操作（对 option 更新）
    //         option.series[params.seriesIndex].parametricEquation =
    //           getParametricEquation(
    //             startRatio,
    //             endRatio,
    //             isSelected,
    //             isHovered,
    //             k,
    //             option.series[params.seriesIndex].pieData.value + 5
    //           );
    //         // if(option.series[params.seriesIndex].pieStatus?.hovered==='undefined') return;
    //         option.series[params.seriesIndex].pieStatus.hovered = isHovered;
    //         // 记录上次高亮的扇形对应的系列号 seriesIndex
    //         hoveredIndex = params.seriesIndex;
    //       }
    //       pieChart.setOption(option);
    //     }
    //   }
    // });
    // 修正取消高亮失败的 bug
    // pieChart.on("globalout", () => {
    //   // 准备重新渲染扇形所需的参数

    //   if (hoveredIndex !== "") {
    //     // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
    //     isSelected = option.series[hoveredIndex].pieStatus?.selected;
    //     isHovered = false;
    //     k = option.series[hoveredIndex].pieStatus?.k;
    //     startRatio = option.series[hoveredIndex].pieData?.startRatio;
    //     endRatio = option.series[hoveredIndex].pieData?.endRatio;

    //     // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
    //     option.series[hoveredIndex].parametricEquation = getParametricEquation(
    //       startRatio,
    //       endRatio,
    //       isSelected,
    //       isHovered,
    //       k,
    //       option.series[hoveredIndex].pieData?.value
    //     );

    //     option.series[hoveredIndex].pieStatus.hovered = isHovered;

    //     // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
    //     hoveredIndex = "";
    //   }
    //   pieChart.setOption(option);
    // });
  }

  // bindListen(pieChart);
}

// 柱状图
function initBarChart() {
  // 柱状图
  // 指定图表的配置项和数据

  let barOption = {
    title: {
      show: true,
      text: `${timeName.value}`,
      right:0,
      bottom:5,
      textStyle:{
        color:'#0CD2EA',
        fontWeight:'normal',
        fontSize:12
      }
    },
    tooltip: {
      show: false,
    },
    grid: {
      top: 35,
      bottom: 30,
      right: 28,
      left: 40,
    },
    legend: {
      show: false,
      data: [t("207")],
    },

    xAxis: [
      {
        type: "category",
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
          nameTextStyle: {
            align: "left",
          },
          // inside: false, //Y轴刻度 false在Y轴左 true在Y轴右
        },
        //改变坐标轴文本的样式
        axisLabel: {
          textStyle: {
            color: "#0CD2EA",
          },
          // formatter:`${timeName.value}`
        },
        //改变坐标轴和文本的样式
        axisLine: {
          lineStyle: {
            color: "#7FFEFF",
          },
        },
        data: barXName.value,
      },
    ],
    yAxis: [
      {
        type: "value",
        show: true,
        name: t("208"),
        splitLine: {
          show: false,
          lineStyle: {
            color: ["#33a2e7"],
          },
        }, //y轴横线
        axisTick: {
          show:false,
          // inside: false, //Y轴刻度 false在Y轴左 true在Y轴右
        },
        //改变坐标轴文本的样式
        axisLabel: {
          interval: "auto",
          textStyle: {
            color: "#0CD2EA",
          },
        },
        //改变坐标轴和文本的样式
        axisLine: {
          show:true,
          lineStyle: {
            color: "#7FFEFF",
          },
        },
      },
    ],
    // dataZoom: [
    //   //滑动条
    //   {
    //     xAxisIndex: 0, //这里是从X轴的0刻度开始
    //     show: false, //是否显示滑动条
    //     type: "inside", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
    //     startValue: 0, // 从头开始。
    //     endValue: 6, // 一次性展示7个。
    //     zoomlock: true,
    //   },
    // ],

    series: [
      {
        name: "Direct",
        type: "bar",
        stack: "value",
        barWidth : 35, //柱图宽度
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        color: "#32BEE6",
        label: {
          show: true,
          position: "inside",
          color: "white",
          formatter: function (params) {
            const a = params.value;
            if (a > 0) {
              return a;
            } else {
              return "";
            }
          },
        },
        data: tjData.value,
      },
    ],
  };

  barChart.setOption(barOption);

  // 自动滚动
  // barTimer.value = setInterval(function () {
  //   // 每次向后滚动一个，最后一个从头开始。
  //   if (barOption.dataZoom[0].endValue == tjData.value.length - 1) {
  //     barOption.dataZoom[0].endValue = 5;
  //    barOption.dataZoom[0].startValue = 0;
  //   } else {
  //     barOption.dataZoom[0].endValue =
  //       barOption.dataZoom[0].endValue + 1;
  //     barOption.dataZoom[0].startValue =
  //       barOption.dataZoom[0].startValue + 1;
  //   }
  //   barChart.setOption(barOption);
  // }, 2000);
}

window.onresize = function () {
  pieWidth = document.body.clientWidth;
  initPieChart();
  barChart.resize();
  pieChart.resize();
  mapChart.resize();
};

onUnmounted(() => {
  mapChart.dispose();
  barChart.dispose();
  pieChart.dispose();
  mapChart = null;
  barChart = null;
  pieChart = null;
});

//监听浏览器小屏且最大化
window.addEventListener("resize", function () {
  barChart.resize();
  pieChart.resize();
  mapChart.resize();
});
</script>

<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #001327;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}
.con_left {
  width: 74%;
  background: url("./../asset/img/home/earth_bg.png") no-repeat;
  background-size: auto 100%;
  background-position: center center;
  position: relative;
  #main {
    width: 100%;
    height: 100%;
  }

  //maptooltip
  .map_tip {
    background: red;
  }

  .select {
    width: 30%;
    height: 2.9375rem;
    position: absolute;
    right: 2%;
    top: 0.3125rem;
    span {
      display: inline-block;
      font-family: Adobe Heiti Std;
    }
    .selectop {
      width: 2.9375rem;
      height: 2.875rem;
      background: #114972;
      text-align: center;
      line-height: 2.875rem;
      position: relative;
      margin-left: 0.875rem;
      cursor: pointer;
    }
    /deep/.el-cascader {
      height: 2.8125rem;
      line-height: 2.8125rem;
    }
    /deep/.el-input {
      --el-input-height: 2.8125rem;
    }
    /deep/.el-input__wrapper {
      border-radius: 0px;
    }
    .teamedit {
      height: 2.8125rem !important;
    }
  }
  .ltop {
    width: 0.5rem;
    height: 0.5rem;
    border-top: 1px solid #00e7ff;
    border-left: 1px solid #00e7ff;
    position: absolute;
    top: -0.0625rem;
    left: -0.0625rem;
  }
  .rtop {
    width: 0.5rem;
    height: 0.5rem;
    border-top: 1px solid #00e7ff;
    border-right: 1px solid #00e7ff;
    position: absolute;
    top: -0.0625rem;
    right: -0.0625rem;
  }
  .lbm {
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 1px solid #00e7ff;
    border-left: 1px solid #00e7ff;
    position: absolute;
    left: -0.0625rem;
    bottom: -0.0625rem;
  }
  .rbm {
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 1px solid #00e7ff;
    border-right: 1px solid #00e7ff;
    position: absolute;
    right: -0.0625rem;
    bottom: -0.0625rem;
  }
  .month {
    width: 5.5rem;
    position: absolute;
    right: 2%;
    bottom: 0.125rem;
    .box {
      position: relative;
      height: 2.0625rem;
      width: 5.5rem;
      background: #114972;
      text-align: center;
      line-height: 2.0625rem;
      margin-bottom: 1.25rem;
      font-size: 1.125rem;
      color: #ffffff;
      cursor: pointer;
    }
    .box:last-child {
      margin-bottom: 0;
    }
    .active {
      background: #32abf9;
    }
  }
}
.con_right {
  display: flex;
  width: 26%;
  flex-direction: column;
  // justify-content: space-between;
  .right_item {
    height: 31%;
    background: rgba(4, 45, 66, 0.9);
    box-shadow: -3px 0px 7px 0px rgba(0, 11, 20, 0.66),
      0px 0px 4px 0px rgba(7, 121, 149, 0.51);
    position: relative;
    margin-bottom: 1.6875rem;
    .item_tp {
      width: 100%;
      position: absolute;
      top: -0.75rem;
      left: 0;
      img {
        width: 100%;
      }
    }
    .item_bm {
      width: 100%;
      position: absolute;
      bottom: -0.4375rem;
      left: 0;
      img {
        width: 100%;
      }
    }
  }
  .right_item:last-child {
    margin-bottom: 0;
  }
  .box {
    width: 100%;
    height: calc(100% - 3.3125rem);
    box-sizing: border-box;
    .fly_one {
      width: 100%;
      height: 100%;
      color: #ffffff;
      .imgbox{
        width: 47%;
        height: auto;
      }
      img {
        width: 100%;
        height: 100%; 
      }
      .fly_number {
        font-size: .75rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .fly_text {
        margin-top: 1.25rem;
        font-size: .75rem;
        color: #fff;
      }
    }
  }

  .right_item_top {
    width: calc(100% - 2.125rem);
    margin: 1.0625rem 1.125rem 0 1rem;
    height: 2.25rem;
    line-height: 2.25rem;
    font-size: .75rem;
    position: relative;
    .rla {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .rlr {
        height: 100%;
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        span {
          display: inline-block;
          position: absolute;
          left: 2.8125rem;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          text-shadow: h-shadow v-shadow white color;
        }
      }
    }

    .rra {
      width: 65%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      .rrr {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          width: 6.125rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: 8.8125rem;
        }
        img:nth-child(2) {
          width: 6.125rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: 4.1875rem;
        }
        img:nth-child(3) {
          display: inline-block;
          width: 5.6875rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
        }

        span {
          display: inline-block;
          position: absolute;
          width: 3.75rem;
          height: 1.25rem;
          text-align: center;
          line-height: 1.25rem;
          top: 0.5625rem;
          right: 10rem;
          font-size: 0.375rem;
          cursor: pointer;
        }
        span:nth-child(5) {
          width: 100%;
          height: 100%;
          width: 3.75rem;
          height: 1.25rem;
          position: absolute;
          top: 0.5625rem;
          right: 5.5rem;
          cursor: pointer;
        }
        span:last-child {
          width: 100%;
          height: 100%;
          width: 3.75rem;
          height: 1.25rem;
          position: absolute;
          top: 0.5625rem;
          right: 0.625rem;
          font-size: 0.375rem;
          cursor: pointer;
        }
      }
    }
  }
  .pie_box {
    width: 100%;
    height: calc(100% - 3.3125rem);
  }
  #pie_main {
    width: 100%;
    height: 100%;
  }
  // 饼图背景
  // .pie_bg {
  //   width: 205px;
  //   height: 88px;
  //   background: url("./../asset/img/home/dz_bg.png") no-repeat;
  //   background-size: 100% 100%;
  //   position: absolute;
  //   left: 14.5px;
  //   bottom: 17.6px;
  //   z-index: 2;
  // }

  // 柱状图
  .bar_box {
    width: 100%;
    height: calc(100% - 3.3125rem);
  }
  #bar_main {
    width: 100%;
    height: 100%;
  }
}
</style>