import { t } from '../languages';
import service from "./service";
const flyQuality = {
    //获取时间范围枚举
    getDateEnumList: () => {
        return service.get({
            url: `/api/v1/statistics/dateEnumList`,
        })
    },
    //获取设备型号统计数据
    getModelStat: (type,region) => {
        return service.get({
            url: `/api/v1/statistics/flyNum?dateEnum=${type}&region=${region}`,
        })
    },
    //获取计算飞行次数
    getFlyStatNum: (type,region) => {
        return service.get({
            url: `/api/v1/statistics/flyTime?dateEnum=${type}&region=${region}`,
        })
    },
    //获取区域任务统计
    getRegoinStat: (region) => {
        return service.get({
            url: `/api/v1/statistics/taskInfo?region=${region}`,
        })
    },
    //统计飞行总数据
    getFlyTotal: (type,region) => {
        return service.get({
            url: `/api/v1/statistics/total?dateEnum=${type}&region=${region}`,
        })
    },
    //存选择区域
    saveRegionCode: (regionCode,id) => {
        return service.post({
            url: `/api/v1/statistics/regionCode?regionCode=${regionCode}&userId=${id}`,
        })
    },
    //取出上次选择区域
    getRegionCode: (id) => {
        return service.get({
            url: `/api/v1/statistics/regionCode?userId=${id}`,
        })
    },
    
}
export default flyQuality
